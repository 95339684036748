import { default as firstrungGtxDh2aW3Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/firstrun.vue?macro=true";
import { default as forgot_45passwordiBclziHQNRMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/forgot-password.vue?macro=true";
import { default as indexHSjQRClQ0aMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/index.vue?macro=true";
import { default as loginnKR5XrtkcVMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/login.vue?macro=true";
import { default as meet_45the_45teamHA7WIRhQ0uMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/meet-the-team.vue?macro=true";
import { default as _91_91filter_93_93zmGG9z98ElMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue?macro=true";
import { default as addb3jGwSuJBOMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/movers/add.vue?macro=true";
import { default as _91id_936Y3F5TJuxAMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/movers/edit/[id].vue?macro=true";
import { default as indexfwgjgGSvw9Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/movers/index.vue?macro=true";
import { default as _91_91step_93_93jvYYenxxf1Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue?macro=true";
import { default as _91_91step_93_93WyOBzsfUw7Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue?macro=true";
import { default as indexzgx1PBAUvkMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/index.vue?macro=true";
import { default as pendingWLkIrZZkslMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/pending.vue?macro=true";
import { default as _91id_93YzEmaJLoNVMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/view/[id].vue?macro=true";
import { default as _91_91step_93_932JTaia7k9YMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue?macro=true";
import { default as indexZpONccACCIMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/index.vue?macro=true";
import { default as addozY3LkGR4QMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/add.vue?macro=true";
import { default as _91id_93GOnJprF0CxMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue?macro=true";
import { default as indexq628fY2tPVMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/index.vue?macro=true";
import { default as _91token_93dvJcAq9Ag5Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/password/reset/[token].vue?macro=true";
import { default as addRDHypbE6rJMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/properties/add.vue?macro=true";
import { default as _91id_93HHWZCaHmXjMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/properties/edit/[id].vue?macro=true";
import { default as indexozZQT268qHMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/properties/index.vue?macro=true";
export default [
  {
    name: firstrungGtxDh2aW3Meta?.name ?? "firstrun",
    path: firstrungGtxDh2aW3Meta?.path ?? "/firstrun",
    meta: firstrungGtxDh2aW3Meta || {},
    alias: firstrungGtxDh2aW3Meta?.alias || [],
    redirect: firstrungGtxDh2aW3Meta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/firstrun.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordiBclziHQNRMeta?.name ?? "forgot-password",
    path: forgot_45passwordiBclziHQNRMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordiBclziHQNRMeta || {},
    alias: forgot_45passwordiBclziHQNRMeta?.alias || [],
    redirect: forgot_45passwordiBclziHQNRMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexHSjQRClQ0aMeta?.name ?? "index",
    path: indexHSjQRClQ0aMeta?.path ?? "/",
    meta: indexHSjQRClQ0aMeta || {},
    alias: indexHSjQRClQ0aMeta?.alias || [],
    redirect: indexHSjQRClQ0aMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginnKR5XrtkcVMeta?.name ?? "login",
    path: loginnKR5XrtkcVMeta?.path ?? "/login",
    meta: loginnKR5XrtkcVMeta || {},
    alias: loginnKR5XrtkcVMeta?.alias || [],
    redirect: loginnKR5XrtkcVMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: meet_45the_45teamHA7WIRhQ0uMeta?.name ?? "meet-the-team",
    path: meet_45the_45teamHA7WIRhQ0uMeta?.path ?? "/meet-the-team",
    meta: meet_45the_45teamHA7WIRhQ0uMeta || {},
    alias: meet_45the_45teamHA7WIRhQ0uMeta?.alias || [],
    redirect: meet_45the_45teamHA7WIRhQ0uMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/meet-the-team.vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_93zmGG9z98ElMeta?.name ?? "move-notifications-index-filter",
    path: _91_91filter_93_93zmGG9z98ElMeta?.path ?? "/move-notifications/:filter?",
    meta: _91_91filter_93_93zmGG9z98ElMeta || {},
    alias: _91_91filter_93_93zmGG9z98ElMeta?.alias || [],
    redirect: _91_91filter_93_93zmGG9z98ElMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: addb3jGwSuJBOMeta?.name ?? "movers-add",
    path: addb3jGwSuJBOMeta?.path ?? "/movers/add",
    meta: addb3jGwSuJBOMeta || {},
    alias: addb3jGwSuJBOMeta?.alias || [],
    redirect: addb3jGwSuJBOMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/movers/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_936Y3F5TJuxAMeta?.name ?? "movers-edit-id",
    path: _91id_936Y3F5TJuxAMeta?.path ?? "/movers/edit/:id()",
    meta: _91id_936Y3F5TJuxAMeta || {},
    alias: _91id_936Y3F5TJuxAMeta?.alias || [],
    redirect: _91id_936Y3F5TJuxAMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/movers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfwgjgGSvw9Meta?.name ?? "movers",
    path: indexfwgjgGSvw9Meta?.path ?? "/movers",
    meta: indexfwgjgGSvw9Meta || {},
    alias: indexfwgjgGSvw9Meta?.alias || [],
    redirect: indexfwgjgGSvw9Meta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/movers/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91step_93_93jvYYenxxf1Meta?.name ?? "moves-add-step",
    path: _91_91step_93_93jvYYenxxf1Meta?.path ?? "/moves/add/:step?",
    meta: _91_91step_93_93jvYYenxxf1Meta || {},
    alias: _91_91step_93_93jvYYenxxf1Meta?.alias || [],
    redirect: _91_91step_93_93jvYYenxxf1Meta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue").then(m => m.default || m)
  },
  {
    name: _91_91step_93_93WyOBzsfUw7Meta?.name ?? "moves-edit-id-step",
    path: _91_91step_93_93WyOBzsfUw7Meta?.path ?? "/moves/edit/:id()/:step?",
    meta: _91_91step_93_93WyOBzsfUw7Meta || {},
    alias: _91_91step_93_93WyOBzsfUw7Meta?.alias || [],
    redirect: _91_91step_93_93WyOBzsfUw7Meta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: indexzgx1PBAUvkMeta?.name ?? "moves",
    path: indexzgx1PBAUvkMeta?.path ?? "/moves",
    meta: indexzgx1PBAUvkMeta || {},
    alias: indexzgx1PBAUvkMeta?.alias || [],
    redirect: indexzgx1PBAUvkMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/index.vue").then(m => m.default || m)
  },
  {
    name: pendingWLkIrZZkslMeta?.name ?? "moves-pending",
    path: pendingWLkIrZZkslMeta?.path ?? "/moves/pending",
    meta: pendingWLkIrZZkslMeta || {},
    alias: pendingWLkIrZZkslMeta?.alias || [],
    redirect: pendingWLkIrZZkslMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/pending.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YzEmaJLoNVMeta?.name ?? "moves-view-id",
    path: _91id_93YzEmaJLoNVMeta?.path ?? "/moves/view/:id()",
    meta: _91id_93YzEmaJLoNVMeta || {},
    alias: _91id_93YzEmaJLoNVMeta?.alias || [],
    redirect: _91id_93YzEmaJLoNVMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_91step_93_932JTaia7k9YMeta?.name ?? "moves-void-id-step",
    path: _91_91step_93_932JTaia7k9YMeta?.path ?? "/moves/void/:id()/:step?",
    meta: _91_91step_93_932JTaia7k9YMeta || {},
    alias: _91_91step_93_932JTaia7k9YMeta?.alias || [],
    redirect: _91_91step_93_932JTaia7k9YMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: indexZpONccACCIMeta?.name ?? "moves-void",
    path: indexZpONccACCIMeta?.path ?? "/moves/void",
    meta: indexZpONccACCIMeta || {},
    alias: indexZpONccACCIMeta?.alias || [],
    redirect: indexZpONccACCIMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/index.vue").then(m => m.default || m)
  },
  {
    name: addozY3LkGR4QMeta?.name ?? "partner-users-add",
    path: addozY3LkGR4QMeta?.path ?? "/partner-users/add",
    meta: addozY3LkGR4QMeta || {},
    alias: addozY3LkGR4QMeta?.alias || [],
    redirect: addozY3LkGR4QMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GOnJprF0CxMeta?.name ?? "partner-users-edit-id",
    path: _91id_93GOnJprF0CxMeta?.path ?? "/partner-users/edit/:id()",
    meta: _91id_93GOnJprF0CxMeta || {},
    alias: _91id_93GOnJprF0CxMeta?.alias || [],
    redirect: _91id_93GOnJprF0CxMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexq628fY2tPVMeta?.name ?? "partner-users",
    path: indexq628fY2tPVMeta?.path ?? "/partner-users",
    meta: indexq628fY2tPVMeta || {},
    alias: indexq628fY2tPVMeta?.alias || [],
    redirect: indexq628fY2tPVMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93dvJcAq9Ag5Meta?.name ?? "password-reset-token",
    path: _91token_93dvJcAq9Ag5Meta?.path ?? "/password/reset/:token()",
    meta: _91token_93dvJcAq9Ag5Meta || {},
    alias: _91token_93dvJcAq9Ag5Meta?.alias || [],
    redirect: _91token_93dvJcAq9Ag5Meta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: addRDHypbE6rJMeta?.name ?? "properties-add",
    path: addRDHypbE6rJMeta?.path ?? "/properties/add",
    meta: addRDHypbE6rJMeta || {},
    alias: addRDHypbE6rJMeta?.alias || [],
    redirect: addRDHypbE6rJMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/properties/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HHWZCaHmXjMeta?.name ?? "properties-edit-id",
    path: _91id_93HHWZCaHmXjMeta?.path ?? "/properties/edit/:id()",
    meta: _91id_93HHWZCaHmXjMeta || {},
    alias: _91id_93HHWZCaHmXjMeta?.alias || [],
    redirect: _91id_93HHWZCaHmXjMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/properties/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexozZQT268qHMeta?.name ?? "properties",
    path: indexozZQT268qHMeta?.path ?? "/properties",
    meta: indexozZQT268qHMeta || {},
    alias: indexozZQT268qHMeta?.alias || [],
    redirect: indexozZQT268qHMeta?.redirect || undefined,
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/properties/index.vue").then(m => m.default || m)
  }
]