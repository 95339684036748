import type {UserTitle} from "~/utils/titles";
import type {DateSchema, StringSchema} from "yup";
import type {FacetList, JmiRecord} from "~/composables/buildStore";
import type {FetchError} from "ofetch";
import type {FunctionalComponent, HTMLAttributes, VNodeProps} from "vue";
import type {VoidEnergySwitchStatus} from "~/utils/voidEnergySwitchStatus";
import type {VoidMeterReadingStatus} from "~/utils/voidMeterReadingStatus";
import type {AutoCoo} from "~/utils/autoCoo";

export type UserType = "jmi" | "partner" | "customer" | "api";

export interface TableHeader {
	label: string,
	id: string,
	sortable?: boolean
}

export type TableHeaders = TableHeader[];

export interface NavigationMenuItem {
	name: string,
	slug: string,
	href: string,
	icon: FunctionalComponent<HTMLAttributes & VNodeProps>,
	current: boolean,
	count: number
}

export interface UserRecord extends JmiRecord {
	usertype: UserType,
	id: string,
	active: boolean,
	full_name: string,
	full_name_and_title: string,
	title: UserTitle,
	firstname: string,
	lastname: string,
	known_email: boolean,
	email: string,
	phone: string,
	altphone: string,
	gdpr_consent: boolean,
	student: boolean,
	partnerid: string[],
	is_all_partners?: boolean,
	partner_names?: string[],
	_formatted?: {
		full_name: string,
		firstname: string,
		lastname: string,
		email: string,
		phone: string,
	}
}

export interface PartnerUserRecord extends UserRecord {
	has_access_to_all_partners: boolean,
	pending_moves_email: boolean,
	partner_admin: boolean,
	partner_names: string[]
}

export interface PartnerRecord extends JmiRecord {
	id: string,
	name: string,
	allow_no_consent_submissions: boolean,
	autocoo: AutoCoo,
	void_address: JmiAddress,
}

export interface SupplierRecord extends JmiRecord {
	id: number,
	name: string,
	suppliernumber: string,
	active: boolean,
	types: SupplierType[],
	does_not_accept_coos: boolean,
	website: string | null,
	phone: string | null
}

export type CooType = "Council" | "Water" | "Gas" | "Sewerage" | "Electricity" | "Heating & Hot Water";

export type SupplierType = "Council" | "Water" | "Gas" | "Sewerage" | "Electricity";

export type PhoneType = "mobile" | "fixed_line" | "any";

export interface MoveRecord extends JmiRecord {
	id: number,
	is_pending: boolean,
	partnerid: string,
	customerid: string,
	created_at: number,
	coos_sent: boolean,
	pause_button_enabled: boolean,
	is_too_late_to_request_void: boolean,
	customer: {
		full_name_and_title: string,
		email: string
	},
	status: string,
	types: ("letting" | "sale")[],
	managed: boolean[],
	direction: ("in" | "out")[],
	void?: VoidState,
	movein?: {
		type: "letting" | "sale" | undefined,
		movedate: number | undefined | null,
		movedate_confirmed: boolean,
		managed: boolean,
		address: string | null,
		property?: PropertyInputs | undefined,
	},
	moveout?: {
		type: "letting" | "sale" | undefined,
		movedate: number | undefined | null,
		movedate_confirmed: boolean,
		managed: boolean,
		address: string | null,
		property?: PropertyInputs | undefined,
	},
	_formatted?: {
		movein: {
			address: string
		},
		moveout: {
			address: string
		},
		customer: {
			full_name_and_title: string
		},
	}
}

export interface VoidState {
	movedate_confirmed: boolean,
	humanized_final_meter_reading_submission_date: string,
	energy_switch: boolean,
	start_date: number | null,
	requested_date: number,
	submission_date: number | null,
	confirmation_date: number | null,
	final_meter_reading_submission_date: number,
	is_submitted: boolean,
	is_after_movedate: boolean,
	meter_readings_sent_at: number | null,
	switch_electricity: boolean,
	switch_gas: boolean,
	has_electricity_reading: boolean,
	has_gas_reading: boolean,
	has_all_necessary_readings: boolean,
	can_submit_readings: boolean,
	has_sent_readings: boolean,
	is_too_late_to_submit: boolean,
	is_too_late_for_meter_readings: boolean,
	is_past_meter_reading_submission_window: boolean,
	needs_readings: boolean,
	needs_movedate_confirmed: boolean,
	needs_gas_reading: boolean,
	needs_electricity_reading: boolean,
	should_send: boolean,
	can_cancel: boolean,
	is_cancelled: boolean,
	status: VoidEnergySwitchStatus,
	meter_reading_status: VoidMeterReadingStatus,
	coos: {
		gas: CooRecord<"Gas">,
		electricity: CooRecord<"Electricity">,
	}
	meters: {
		electricity: VoidMeterData<"Electricity">[],
		gas: VoidMeterData<"Gas">[],
	},
}

export interface ExtendedMoveRecord extends MoveRecord {
	partner_submission_notes: string,
	customer: CustomerInputs & {
		full_name_and_title: string,
		email: string
	},
	movein?: Omit<MoveEventInputs, "movedate" | "id"> & {
		id: number,
		address: string | null,
		movedate: number | undefined,
		occupants: OccupantInputs[],
	},
	moveout?: Omit<MoveOutEventInputs, "movedate" | "id"> & {
		id: number,
		address: string | null,
		movedate: number | undefined,
		occupants: OccupantInputs[],
	},
}

export type TableFilters<K extends FacetList = FacetList> = Partial<{
	[prop in keyof K]: {
		title: string,
		options?: Array<{ label: string, id: string | undefined | boolean }>,
		type?: 'date-range' | 'select' | 'multiselect',
		placeholder?: string
	}
}>

export type MoveRecordInputs = ExtendedMoveRecord & {
	customer: CustomerInputs
}

export interface SupplierState {
	council: SupplierRecord[],
	water: SupplierRecord[],
	sewerage: SupplierRecord[],
	gas: SupplierRecord[],
	electricity: SupplierRecord[],
	booted?: boolean
}

export type MoveEventType = "letting" | "sale";
export type Bill = "water" | "sewerage" | "counciltax" | "gas" | "electric" | "broadband" | "tv";
export type InterimDestination = "agency" | "landlord" | "property";
export type MoveDirection = "in" | "out";

export interface MoveEvent {
	type: MoveEventType | undefined,
	formatted_type: string | undefined,
	movedate: string,
	movedate_confirmed: boolean,
	managed: boolean,
	address: string | null,
	property: PropertyInputs | undefined,
}

export interface StepsDetail {
	id: string,
	name: string,
	skippable?: boolean,
	slug: string,
	disabled?: boolean,
	needStepConfirmation?: boolean,
	displayConfirmation?: boolean,
	stepAutoSkip?: boolean,
	stepAutoSubmit?: boolean,
	stepAutoCancel?: boolean,
}

export type StepsList = Record<string, StepsDetail>;

export interface JmiAddress {
	address1: string,
	address2: string,
	city: string,
	county: string,
	postcode: string,
	manual_override: boolean,
}

export interface ComputedStepsDetail extends StepsDetail {
	isComplete: boolean,
	isCurrent: boolean,
	isFirst: boolean,
	isLast: boolean,
	canGoTo: boolean,
	hasNextSubmittableStep: boolean,
	previousStepId?: string | undefined,
	nextStepId?: string | undefined,
}

export interface PropertyInputs {
	exists: boolean | null,
	id: number | null,
	label: string | null,
	address: JmiAddress | null
}

export interface OccupantInputs {
	id?: number | null,
	title?: UserTitle | null,
	firstname?: string | null,
	lastname?: string | null,
	email?: string | null,
	student?: boolean,
	known_email?: boolean,
	phone?: string | null,
}

export interface MoverInputs {
	title: UserTitle | null,
	firstname: string | null,
	lastname: string | null,
	email: string | null,
	phone: string | null,
	altphone: string | null,
	known_email: boolean | null,
	gdpr_consent: boolean | null,
	student: boolean | null,
	partner_id: string | null
}

export interface CustomerInputs extends Omit<MoverInputs, "partner_id"> {
	exists: boolean | null,
	dirty: boolean | null,
	id: string | null,
	full_name_and_title: string | null,
}

export interface AdditionalInformationInputs {
	partner_id: string | undefined,
	has_other_occupants: boolean,
	additional_information: string | null,
	consent: boolean | undefined,
}

export interface GasOrElectricityLookupResponse {
	options: Record<string, string>,
	match: string | null,
	meters: MeterInputs[]
}

export interface MoveEventInputs {
	id: number | null,
	property: PropertyInputs | undefined,
	type: MoveEventType | undefined,
	movedate: string | undefined,
	managed: boolean,
	movedate_confirmed: boolean,
	any_bills_included: boolean,
	bills_included: Bill[],
	gas_at_property: boolean,
	heating_and_hot_water_billed_separately: boolean,
	heating_and_hot_water_in_name_of_tenant: boolean,
	heating_and_hot_water_supplier_name: string | null,
	heating_and_hot_water_supplier_email: string | null,
	heating_and_hot_water_supplier_phone: string | null,
	heating_and_hot_water_account_ref: string | null,
	heating_and_hot_water_meter_readings: string | null,
	occupants: OccupantInputs[],
}

export interface ForwardingAddressInputs {
	type: "movein" | "uk" | "foreign" | "unknown",
	foreign_forwarding_address: string | null,
	address: JmiAddress | null
}

export interface MoveOutEventInputs extends MoveEventInputs {
	forwarding_address: ForwardingAddressInputs | null,
	interim_period: InterimPeriodInputs | null,
}

export interface SupplyMeter {
	serial_number: string | undefined
}

export interface PropertyRecord extends JmiRecord {
	id: number,
	partnerid: string[],
	full_address: string,
	address1: string,
	address2: string,
	city: string,
	county: string,
	postcode: string,
	uprn: string,
	managed: boolean,
	manual_override: boolean,
	gas_at_property: string | null,
	_formatted: {
		address1: string,
		address2: string,
		city: string,
		county: string,
		postcode: string,
	}
}

export interface ExtendedPropertyRecord extends PropertyRecord {
	council: number | null,
	water_providedby: number | null,
	sewerage_providedby: number | null,
	gas_at_property: boolean,
	has_water_meter: boolean,
	meters: {
		gas: SupplyMeter[],
		water: SupplyMeter[],
		electricity: SupplyMeter[],
	}
}

export interface MeterInputs {
	id: number | null,
	mpxn: string | null,
	serial_number: string | null
}

export interface BankAccountInputs {
	sort_code: string | null,
	account_number: string | null,
	account_name: string | null,
}

export interface InterimPeriodInputs {
	has_interim: boolean,
	interim_is_empty: boolean,
	interim_destination: InterimDestination | null,
	landlord_address: JmiAddress | null,
	interim_contact_title: string | null,
	interim_contact_firstname: string | null,
	interim_contact_lastname: string | null,
	interim_contact_phone: string | null,
	interim_contact_email: string | null,
	foreign_address: string | null,
	address_type: "uk" | "foreign",
}

export interface VoidSwitchInputs {
	submit: boolean,
	has_end_date: boolean | null,
	end_date: string | null,
	bank_account: BankAccountInputs,
	switch_gas: boolean,
	switch_electricity: boolean,
	electricity_match: string | null,
	gas_match: string | null,
	meters: {
		gas: MeterInputs[],
		electricity: MeterInputs[],
	}
	manual_gas_match: boolean,
	manual_electricity_match: boolean,
}

export type Tuple<T, N extends number, R extends T[] = [], > = R['length'] extends N ? R : Tuple<T, N, [T, ...R]>;

// This is the type used by <Datepicker>, it's two
export type DateRange = Tuple<number, 2>;

export interface CouncilLookupResponse {
	council: number | null
}

export interface WaterSewerageLookupResponse {
	water: number | null
	sewerage: number | null
}

export enum ProfileClass {
	Unknown = '',
	FlatRate = '01',
	Eco7Eco10 = '02',
	Commercial = '03',
	Industrial = '04',
	NonDomestic05 = '05',
	NonDomestic06 = '06',
	NonDomestic07 = '07',
	Domestic = '00',
}

export enum ProfileClassNames {
	Unknown = 'Unknown',
	FlatRate = '01 - Flat Rate',
	Eco7Eco10 = '02 - ECO 7 / ECO 10',
	Commercial = '03 - Commercial',
	Industrial = '04 - Industrial',
	NonDomestic05 = '05 - Non-Domestic',
	NonDomestic06 = '06 - Non-Domestic 06',
	NonDomestic07 = '07 - Non-Domestic 07',
	Domestic = '00 - Domestic',
}

type CooAdditionalInformationProperties = {
	Gas: {
		mprn: string | null,
	},
	Electricity: {
		mpan: string | null,
		profile_class: ProfileClass | null,
	},
	Council: Record<string, never>,
	Water: Record<string, never>,
	Sewerage: Record<string, never>,
	"Heating & Hot Water": Record<string, never>,
};

export type CooAdditionalInformation<K extends CooType = CooType> = {
	id: number,
	cooid: number,
	sn: string | null,
	partner_meter_read: string | null,
	partner_meter_read_date: number | null,
	customer_meter_read_date: number | null,
	customer_meter_read: string | null,
} & CooAdditionalInformationProperties[K]

export type CooAdditionalInformationInputs<K extends CooType = CooType> = Omit<CooAdditionalInformation<K>, "id" | "partner_meter_read_date" | "customer_meter_read_date"> & {
	id: number | null,
	partner_meter_read_date: string | null, // YYYY-MM-DD
	customer_meter_read_date: string | null, // YYYY-MM-DD
};

export type VoidMeterData<K extends CooType = CooType> = Omit<CooAdditionalInformation<K>, "id" | "cooid" | "partner_meter_read_date" | "partner_meter_read">;

// This should match \App\Enums\PublicCooStatus
export type CooStatus = "Missing Information" | "Sent" | "Not Required" | "Failed to Send" | "On Hold" | "Sendable" | "Unable to Send";

export interface CooRecord<K extends CooType = CooType> extends JmiRecord {
	id: number,
	partnerid: string[],
	address: string,
	movetype: MoveEventType,
	direction: MoveDirection,
	type: K,
	status: CooStatus,
	scheduled: number,
	has_movedate: boolean,
	movedate: number | null,
	human_scheduled: string | null, // jS F Y
	supplier: {
		name: string,
		website: string | null,
		phone: string | null,
		accepts_coos: boolean,
	} | null,
	errors: string[],
	moveid: number,
	updated_at: number,
	additional_information: Array<CooAdditionalInformation<K>>,
	_formatted: {
		address: string,
	}
	can_submit_readings: boolean,
	has_any_partner_meter_readings: boolean,
}

export type KnownIndexes = "moves" | "users" | "coos" | "properties" | "suppliers" | "partners";

export interface StoreRecordMap {
	_changes: true, // This is an unused property to help the type guard.
	coos: CooRecord,
	movers: UserRecord,
	moves: ExtendedMoveRecord,
	partner_users: UserRecord,
	partners: PartnerRecord,
	properties: ExtendedPropertyRecord,
}

export interface DeletedRecord {
	_deleted: true,
	id: number | string,
}

export type PendingChanges = {
	[prop in keyof StoreRecordMap]?: StoreRecordMap[prop][]
}

export function isDeletedRecord(value: unknown): value is DeletedRecord {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, '_deleted');
}

export function isPendingChanges(value: unknown): value is PendingChanges {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, '_changes');
}

export function isFetchError(value: unknown): value is FetchError {
	return typeof value === 'object' && value !== null && typeof value.name !== "undefined" && value.name === "FetchError";
}

export function isJmiAddress(value: unknown): value is JmiAddress {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'postcode');
}

export function isJmiRecord(value: unknown): value is JmiRecord {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'id');
}

export function isDateSchema(value: unknown): value is DateSchema<Date | undefined | null> {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'type') && value.type === 'date';
}

export function isError(value: unknown): value is Error {
	return value instanceof Error;
}

export function isStringSchema(value: unknown): value is StringSchema<string | undefined | null> {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'type') && value.type === 'string';
}

export function isPropertyInputs(value: unknown): value is PropertyInputs {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'address');
}

export function isMoveRecord(value: unknown): value is MoveRecord {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'managed');
}

export function isGasOrElectricityLookupResponse(value: unknown): value is GasOrElectricityLookupResponse {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'match');
}

export function isExtendedMoveRecord(value: unknown): value is ExtendedMoveRecord {
	return typeof value === 'object' && value !== null && Object.hasOwn(value, 'partner_submission_notes');
}

export function isCooAdditionalInformation<K extends CooType>(type: K, coo: CooRecord<CooType>, value: object): value is CooAdditionalInformation<K> {
	return coo.id === value.cooid && coo.type === type;
}

export function isVoidMeterData<K extends CooType>(type: K, value: unknown): value is VoidMeterData<K> {
	if (type === "Gas") {
		return typeof value === 'object' && value !== null && Object.hasOwn(value, 'mprn');
	} else if (type === "Electricity") {
		return typeof value === 'object' && value !== null && Object.hasOwn(value, 'mpan');
	} else {
		throw new Error(`Invalid Void Meter Data type: ${type}`);
	}
}
