import {acceptHMRUpdate, defineStore} from 'pinia';
import {FetchError} from "ofetch";
import useJmiFetch from "~/composables/use-jmi-fetch";
import {useProperties} from "~/stores/properties";
import {useCoos} from "~/stores/coos";
import type {BankModulusCheckResponse, ResetPasswordResponse, ValidateEmailResponse, ValidatePhoneNumberResponse} from "~/utils/responses";
import {isFetchError, type KnownIndexes, type PartnerRecord} from "~/utils/types";
import type {UserType, PhoneType} from "~/utils/types";
import {useMovers} from "~/stores/movers";
import {useMoves} from "~/stores/moves";
import {PartnerFacets, usePartners} from "~/stores/partners";
import {usePartnerUsers} from "~/stores/partnerUsers";
import {useSuppliers} from "~/stores/suppliers";
import {parse} from "date-fns";
import type {RouteLocationNormalized} from "vue-router";
import * as Sentry from "@sentry/vue";

export interface Partner {
	id: string,
	name: string,
	has_access: boolean,
	api_key?: string,
}

export interface UserPermissions {
	can_view_pending_moves: boolean,
	can_access_voids: boolean,
}

type TeamContacts = "am" | "umt" | "vst";

export interface JmiTeamMember {
	partnerportal_teamcontact: TeamContacts[],
	img: string,
	full_name: string
}

export type IndexNameList = {
	[prop in KnownIndexes]: string
};

export interface LoggedInUser {
	id: string,
	full_name: string,
	email: string,
	phone: string,
	start_date: number,
	usertype: UserType,
	partners: Partner[],
	lettings_partnership_model: "integrated" | "introducer" | null,
	sales_partnership_model: "integrated" | "introducer" | null,
	meilisearch_token: string,
	active_parent_partner_id: string | null,
	active_partner_id: string | null,
	has_read_move_notifications_banner: boolean,
	has_seen_first_run_page: boolean,
	partner_admin: boolean,
	force_password_change: boolean,
	permissions: UserPermissions,
	team: JmiTeamMember[],
	trustpilot: {
		star_rating: number,
		review_count: number
	},
	indexes: IndexNameList
}

export type LoggedInUserResponse = {
	logged_in: false
} | {
	logged_in: true,
	user: LoggedInUser
};

export type UpdatePasswordResponse = {
	ok: true,
	message: string,
	user_data: LoggedInUser
};

interface SessionState {
	originalRequest: string,
	booted: boolean,
	loggingIn: boolean,
	errorMessage: string | undefined,
	user: LoggedInUser | undefined,
	activePartnerId: string | null,
	lastEmailAvailableCheck: string | null,
	currentRoute: RouteLocationNormalized | undefined,
	partners: PartnerRecord[],
	validatedPhoneNumbers: Map<string, boolean>,
	validatedEmails: Map<string, boolean>,
	emailAbortController: AbortController,
	phoneAbortController: AbortController,
	getUserDetailsAbortController: AbortController,
}

export const useSession = defineStore('session', {
	state: (): SessionState => ({
		originalRequest: "/",
		booted: false,
		loggingIn: false,
		errorMessage: undefined,
		user: undefined,
		activePartnerId: null,
		lastEmailAvailableCheck: null,
		currentRoute: undefined,
		partners: [],
		validatedPhoneNumbers: new Map(),
		validatedEmails: new Map(),
		emailAbortController: new AbortController(),
		phoneAbortController: new AbortController(),
		getUserDetailsAbortController: new AbortController(),
	}),

	getters: {
		has_read_move_notifications_banner: (state) => {
			return state.user?.has_read_move_notifications_banner ?? false;
		},

		partner_ids: (state): string[] => {
			if (state.user) {
				return state.user.partners.map(function (partner) {
					return partner.id;
				});
			} else {
				return [];
			}
		},

		allowed_partners: (state): Array<{ id: string, label: string }> => {
			if (state.user) {
				return state.user.partners.filter((partner: Partner) => {
					return partner.has_access;
				}).map((partner: Partner) => {
					return {
						id: partner.id,
						label: partner.name
					}
				});
			} else {
				return [];
			}
		},

		start_date(state: SessionState): Date {
			if (state.user) {
				return new Date(state.user.start_date * 1000);
			} else {
				return parse('2015-01-01', 'yyyy-MM-dd', new Date());
			}
		},

		partners_with_api_keys: (state) => {
			if (state.user?.partner_admin) {
				return state.user.partners.filter(function (partner) {
					return !!partner.api_key;
				});
			} else {
				return [];
			}
		},

		ask_for_gdpr_consent: (state): boolean => {
			return state.user?.lettings_partnership_model === "introducer" || state.user?.sales_partnership_model === "introducer";
		},

		default_partner_id(): string | null {
			if (this.allowed_partners.length === 1 && this.allowed_partners[0]) {
				return this.allowed_partners[0].id;
			} else {
				return null;
			}
		}
	},

	actions: {
		async isEmailAvailable(value: string, id: number | string | undefined = undefined): Promise<boolean> {
			if (this.validatedEmails.has(value)) {
				return this.validatedEmails.get(value) ?? false;
			} else {
				const {post} = useJmiFetch();

				try {
					this.emailAbortController.abort();
					this.emailAbortController = new AbortController();

					const result = await post<ValidateEmailResponse>(`/validate-email` + (id ? ("/" + id) : ""), {
						email: value
					}, {
						signal: this.emailAbortController.signal
					});

					const isValid = result.valid;
					this.validatedEmails.set(value, isValid);
					return isValid;
				} catch (e) {
					// If the request was aborted or the server returned an error, assume the email is not available, but don't cache.
					return false;
				}
			}
		},

		async bankModulusCheck(sortCode: string, accountNumber: string): Promise<boolean> {
			const {get} = useJmiFetch();
			const result = await get<BankModulusCheckResponse>(`/validate-modulus/` + sortCode + `/` + accountNumber);
			return result.valid;
		},

		async isPhoneNumberValid(phoneNumber: string, phoneType: PhoneType): Promise<boolean> {
			if (this.validatedPhoneNumbers.has(phoneNumber)) {
				return this.validatedPhoneNumbers.get(phoneNumber) ?? false;
			} else {
				const {post} = useJmiFetch();

				try {
					this.phoneAbortController.abort();
					this.phoneAbortController = new AbortController();

					const result = await post<ValidatePhoneNumberResponse>(`/validate-phone-number`, {
						phone_type: phoneType,
						phone_number: phoneNumber
					}, {
						signal: this.phoneAbortController.signal
					});
					this.validatedPhoneNumbers.set(phoneNumber, result.valid);
					return result.valid;
				} catch (e) {
					// If the request was aborted or the server returned an error, assume the validation failed, but don't cache.
					return false;
				}
			}
		},

		async refreshPartners(): Promise<PartnerRecord[]> {
			if (this.user) {
				const store = usePartners();

				const queryFacets: Partial<PartnerFacets> = {};

				if (this.user.active_parent_partner_id) {
					queryFacets["parentid"] = this.user.active_parent_partner_id;
				}

				await store.boot("session", {
					perPage: 1000000,
					useGlobalCounts: false,
					useCachedFacets: false,
					sort: {
						"name": "asc"
					},
					queryFacets: queryFacets,
				});

				this.partners = store.instances["session"]?.queryData ?? [];
			} else {
				this.partners = [];
			}

			return this.partners;
		},

		async markMoveNotificationsBannerAsRead() {
			const {post} = useJmiFetch();
			// Linter disabled because of the state mutation of the user object
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.user!.has_read_move_notifications_banner = true;
			localStorage.setItem('logged_in_user', JSON.stringify(this.user));
			return post('/coos/mark-banner-as-read', {});
		},

		async markFirstRunPageAsSeen() {
			// Linter disabled because of the state mutation of the user object
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.user!.has_seen_first_run_page = true;
			localStorage.setItem('logged_in_user', JSON.stringify(this.user));
		},

		async refreshMeiliSearchToken() {
			const coos = useCoos();
			const movers = useMovers();
			const moves = useMoves();
			const partners = usePartners();
			const partnerUsers = usePartnerUsers();
			const properties = useProperties();
			const suppliers = useSuppliers();

			return Promise.all([
				coos.restartMeiliSearchClient().refresh(),
				movers.restartMeiliSearchClient().refresh(),
				moves.restartMeiliSearchClient().refresh(),
				partners.restartMeiliSearchClient().refresh(),
				partnerUsers.restartMeiliSearchClient().refresh(),
				properties.restartMeiliSearchClient().refresh(),
				suppliers.restartMeiliSearchClient().refresh(),
			]);
		},

		async setActiveParentPartnerId(id: string | null) {
			const {post} = useJmiFetch();
			this.loggingIn = true;
			try {
				const user = await post<LoggedInUser>("/set-parent-account-override", {partnerid: id});
				await this.setUserDetails(user);
			} catch (e) {
				await this.handleError(e);
			} finally {
				this.loggingIn = false;
			}
		},

		async setActivePartnerId(id?: string) {
			const {post} = useJmiFetch();

			if (!this.user) {
				throw new Error("Can't setActivePartnerId if the user is not logged in.");
			}

			this.activePartnerId = id ?? null;
			Sentry.setTag("partner_id", this.activePartnerId ?? null);

			const new_user = this.user;
			new_user.active_partner_id = id ?? null;
			await this.setUserDetails(new_user);

			return Promise.all([
				this.refreshMeiliSearchToken(),
				post("/set-account-override", {partnerid: id})
			]);
		},

		setOriginalRequest(value: string) {
			this.originalRequest = value.replace(/\/+$/, '');
		},

		async boot(to: RouteLocationNormalized) {
			this.currentRoute = to;

			if (!this.booted) {
				const stored_user = localStorage.getItem('logged_in_user');
				const stored_user_object = stored_user ? JSON.parse(stored_user) as LoggedInUser : undefined;
				await this.loadUserDataIntoState(stored_user_object);

				// Refresh user details from the backend.
				await this.getLoggedInUserDetails();
			}
		},

		async getLoggedInUserDetails(): Promise<LoggedInUser | null> {
			const {post} = useJmiFetch();
			const controller = this.getUserDetailsAbortController;

			try {
				// If the user had a branch selected before, set it again before refreshing the user details.

				controller.abort();
				this.getUserDetailsAbortController = new AbortController();

				const response = await post<LoggedInUserResponse>('/user', {
					active_parent_partner_id: this.user?.active_parent_partner_id,
					active_partner_id: this.user?.active_partner_id,
				}, {
					signal: this.getUserDetailsAbortController.signal
				});

				if (response.logged_in) {
					await this.setUserDetails(response.user);
					return response.user;
				} else {
					await this.setUserDetails();
				}
			} catch (e) {
				// If the request was aborted, don't throw an error.
				if (controller.signal.aborted) {
					return null;
				}

				await this.handleError(e, [401]);
			}

			return null;
		},

		async handleError(e: unknown, ignoreErrors: number[] = []) {
			if (e instanceof FetchError) {
				if (e.statusCode) {
					if (!ignoreErrors.includes(e.statusCode)) {
						this.errorMessage = 'An unknown error occurred, refresh and try again.';
						await this.setUserDetails();
					}
				} else {
					// This is a network error, throw it so the error page can be shown.
					this.errorMessage = 'An unknown error occurred, refresh and try again.';
					throw e;
				}
			} else {
				this.errorMessage = 'An unknown error occurred, refresh and try again.';
				throw e;
			}
		},

		handleLoggedOut({path, fullPath}: RouteLocationNormalized) {
			const trimmedPath = path.replace(/\/+$/, '');

			const checkRouteArrayContainsPath = (array: string[], value: string) => {
				let contains = false;

				array.forEach(element => {
					if (element === value || (element.includes('*') && value.startsWith(element.replace('*', '')))) {
						contains = true;
					}
				});

				return contains;
			}

			const unprotectedRoutesWithRedirect = [
				"/login",
				"/forgot-password",
				"/password/reset/*",
			];

			if (!checkRouteArrayContainsPath(unprotectedRoutesWithRedirect, trimmedPath)) {
				this.setOriginalRequest(fullPath);
				return navigateTo('/login');
			}

			return;
		},
		handleLoggedIn({path}: RouteLocationNormalized) {
			const trimmedPath = path.replace(/\/+$/, '');

			if (this.user) {
				if (this.user.usertype !== "jmi" || this.user.active_parent_partner_id) {
					if (["/firstrun", "/login"].indexOf(trimmedPath) !== -1 && this.user?.has_seen_first_run_page) {
						const redirect = (["/firstrun", "/login"].indexOf(this.originalRequest) !== -1) ? '/' : this.originalRequest;
						return navigateTo(redirect);
					} else if (trimmedPath !== "/firstrun" && !this.user?.has_seen_first_run_page) {
						return navigateTo('/firstrun');
					}
				}
			}

			return;
		},

		async loadUserDataIntoState(user ?: LoggedInUser) {
			if (user) {
				this.errorMessage = undefined;
				this.user = user;

				if (this.user.active_partner_id) {
					this.activePartnerId = this.user.active_partner_id;
				}

				Sentry.setUser({
					email: this.user.email,
					id: this.user.id,
					username: this.user.full_name,
					segment: this.user.usertype,
				});

				Sentry.setTag("partner_id", this.user.active_partner_id ?? null);
				Sentry.setTag("parent_partner_id", this.user.active_parent_partner_id ?? null);

				await this.refreshMeiliSearchToken();
				void this.refreshPartners();
			} else {
				this.activePartnerId = null;
				this.user = undefined;

				Sentry.setUser(null);
				Sentry.setTag("partner_id", null);
				Sentry.setTag("parent_partner_id", null);
			}

			this.loggingIn = false;
			this.booted = true;
		},

		async setUserDetails(user ?: LoggedInUser) {
			const route = this.currentRoute ? this.currentRoute : useRoute();

			// Reset the current route after using it. That will make it use useRoute() in situations where it didn't get set by the middleware.
			this.currentRoute = undefined;

			try {
				await this.loadUserDataIntoState(user);

				if (user) {
					await useCoos().boot('login', {
						useGlobalCounts: false
					});

					localStorage.setItem('logged_in_user', JSON.stringify(user));

					if (route.name === 'login') {
						this.handleLoggedIn(route);
					}
				} else {
					localStorage.removeItem('logged_in_user');
					this.handleLoggedOut(route);
				}
			} catch (error) {
				localStorage.removeItem('logged_in_user');
				void this.loadUserDataIntoState(undefined);
				this.handleLoggedOut(route);
				throw error;
			}
		},
		setPasswordSuccessfullyUpdated(email: string) {
			localStorage.setItem('password_success_updated', email);
		},
		getPasswordSuccessfullyUpdated() {
			const email = localStorage.getItem('password_success_updated');
			if (email) {
				localStorage.removeItem('password_success_updated');
			}
			return email;
		},
		async editPassword(current_password: string, new_password: string, new_password_confirmation: string) {
			const {post} = useJmiFetch();

			const response = await post<UpdatePasswordResponse>('/update-password', {
				current_password,
				new_password,
				new_password_confirmation
			});

			await this.setUserDetails(response.user_data);

			return response;
		},

		async resetPasswordRequest(userId: string) {
			const {fetch} = useJmiFetch();

			return fetch<ResetPasswordResponse>('/users/' + userId + '/reset-password', {
				method: 'PATCH',
				body: {
					autogenerate: true,
					passwordemail: false,
					force_password_change: true
				}
			});
		},

		async logout() {
			if (this.user) {
				const {get} = useJmiFetch();
				await get('/logout', {
					redirect: "manual"
				});
				await this.setUserDetails();
			}
		},

		async login(username: string, password: string, remember: boolean) {
			const {post} = useJmiFetch();
			this.loggingIn = true;

			try {
				const user = await post<LoggedInUser>('/../../login', {
					email: username,
					password: password,
					remember: remember
				});

				if (user) {
					await this.setUserDetails(user);
				}
			} catch (error) {
				this.loggingIn = false;

				if (isFetchError(error)) {
					if (error.response?.status === 401) {
						this.errorMessage = error.data.message;
					} else {
						// Is the user previously logged in?
						const user = await this.getLoggedInUserDetails();

						if (!user) {
							this.errorMessage = 'An unknown error occurred, refresh and try again.';
							throw error;
						}
					}
				} else {
					this.errorMessage = 'An unknown error occurred, refresh and try again.';
					throw error;
				}
			} finally {
				this.loggingIn = false;
			}
		},
		can(permissionCheck: keyof UserPermissions): boolean {
			// Now check if the user has the permission object key.
			return this.user?.permissions[permissionCheck] ?? false;
		},
		lastSeenAtTracking() {
			// Ping the tab every 2 minutes to make sure the user is still logged in.
			setInterval(() => {
				if (this.user) {
					void this.getLoggedInUserDetails();
				}
			}, 120000);
		}
	},
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useSession, import.meta.hot))
}
