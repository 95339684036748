export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/images/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/images/favicon-128x128.png","sizes":"128x128"},{"rel":"icon","type":"image/png","href":"/images/favicon-180x180.png","sizes":"180x180"},{"rel":"icon","type":"image/png","href":"/images/favicon-192x192.png","sizes":"192x192"}],"style":[],"script":[{}],"noscript":[{}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null